<template>
  <footer class="bg-light mt-auto">
    <div class="container pt-5">
      <div class="row">
        <div class="col-md-6">
          <div class="">
            <img
              src="/assets/img/logo.svg"
              width="119"
              height="52"
              alt=""
              class="mb-4"
            />
            <h1 class="font-size-48 font-weight-bold mb-4">
              Get new information about Degree VIP Club
            </h1>
            <div
              class="input-group mb-3 border-neutral100 p-2 border rounded-lg"
              style="max-width: 420px"
            >
              <input
                type="text"
                class="form-control border-0"
                placeholder="Type your email"
                aria-label="Type your email"
                aria-describedby="button-addon2"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary500 text-white px-5"
                  type="button"
                  id="button-addon2"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-4">
              <div class="">
                <p class="font-weight-semi-bold mb-4">HOW IT WORKS</p>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Download App</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Register</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Add New Card</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Transaction</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="">
                <p class="font-weight-semi-bold mb-4">FEATURES</p>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Manage Care</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >FQHCs</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Medicare</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Medicaid</a
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="">
                <p class="font-weight-semi-bold mb-4">ABOUT US</p>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >About Us</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Contact Us</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400"
                    >Testimony</a
                  >
                </div>
                <div class="footer-link mb-4">
                  <a href="" class="font-weight-medium text-neutral400">FAQ</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line mt-4 bg-ede8e9"></div>
      <div class="py-4">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div class="">
              <a class="text-neutral300">©2023 DVG LTD. All rights reserved</a>
            </div>
            <div class="dot bg-neutral300 mx-3"></div>
            <div class="">
              <a class="text-neutral300" href="#">Privacy Policy</a>
            </div>
            <div class="dot bg-neutral300 mx-3"></div>
            <div class="">
              <a class="text-neutral300" href="#">Term of Use</a>
            </div>
          </div>
          <div class="d-flex">
            <a href="" class="text-neutral500 mr-3">
              <div class="border borderede8e9 p-2_4 rounded-circle">
                <vsx-icon
                  iconName="Instagram"
                  :size="24"
                  type="bold"
                  class=""
                />
              </div>
            </a>
            <a href="" class="text-neutral500">
              <div class="border borderede8e9 p-2_4 rounded-circle">
                <vsx-icon
                  iconName="Instagram"
                  :size="24"
                  type="bold"
                  class=""
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
  import { vsxIcon } from "vue-iconsax";

  export default {
    components: {
      vsxIcon,
    },
    props: ["iconName"],
  };
</script>
