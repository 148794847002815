<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png" />
  <HelloWorld msg="Welcome to Your Vue.js App" /> -->

  <div
    class="d-flex flex-column min-vh-100 position-relative"
    v-if="$route.meta.landing === false"
  >
    <!-- Navbar -->
    <NavbarMerch v-if="$route.meta.merchant === true" />
    <Navbar v-else />
    <div class="container h-100 py-4">
      <!-- Content -->
      <router-view />
    </div>
    <!-- footer -->
    <FooterMerch v-if="$route.meta.merchant === true" />
    <Footer v-else />
  </div>
  <div class="" v-else>
    <NavbarLanding />

    <!-- Content -->
    <router-view />

    <FooterLanding />
  </div>
</template>

<script>
  // import HelloWorld from "./components/HelloWorld.vue";

  // Components
  import Footer from "./components/Footer.vue";
  import Navbar from "./components/Navbar.vue";
  import FooterMerch from "./components/merchant/Footer.vue";
  import NavbarMerch from "./components/merchant/Navbar.vue";
  import FooterLanding from "./components/landingpage/Footer.vue";
  import NavbarLanding from "./components/landingpage/Navbar.vue";

  // import { vsxIcon } from "vue-iconsax";
  export default {
    name: "App",
    components: {
      Navbar,
      Footer,
      NavbarMerch,
      FooterMerch,
      NavbarLanding,
      FooterLanding,

      // vsxIcon,
    },
    // props: ["iconName"],
  };
</script>

<style></style>
