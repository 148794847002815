<template>
  <div class="bg-white">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light bg-light py-3">
        <a class="navbar-brand mr-lg-5 pr-lg-4" href="/">
          <img
            src="/assets/img/logo.svg"
            class="img-fluid"
            width="73"
            height="32"
            alt=""
          />
        </a>
        <div
          v-if="$route.meta.logged === true"
          class="d-lg-none d-block ml-auto"
        >
          <Notif />
        </div>
        <div
          v-if="$route.meta.logged === false"
          class="d-lg-none d-block ml-auto"
        >
          <a
            href="/merchant"
            class="btn btn-outline-neutral500 px-4 py-2 font-weight-bold font-size-14"
            :class="$route.meta.logged === true ? '' : 'ml-auto'"
            type="submit"
          >
            Switch to Merchant
          </a>
        </div>
        <button
          v-if="$route.meta.logged === true"
          class="navbar-toggler border-0"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          class="collapse navbar-collapse ml-lg-2"
          id="navbarSupportedContent"
        >
          <div class="d-flex justify-content-between d-lg-none d-block">
            <div class="align-self-center">
              <a
                href="/merchant"
                class="btn btn-outline-neutral500 px-4 py-2 font-weight-bold font-size-14 my-3 d-block d-lg-inline-block my-lg-0"
                :class="$route.meta.logged === true ? '' : 'ml-auto'"
                type="submit"
              >
                Switch to Merchant
              </a>
            </div>
            <div class="align-self-center">
              <button
                v-if="$route.meta.logged === true"
                class="navbar-toggler border-0"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="#2c3131"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16748 4.1665L15.8334 15.8324"
                      stroke="#2c3131"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.16664 15.8324L15.8325 4.1665"
                      stroke="#2c3131"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <ul class="navbar-nav mr-auto" v-if="$route.meta.logged === true">
            <li
              :class="$route.name == 'Dashboard' ? 'active' : ''"
              class="my-2 my-lg-0 nav-item"
            >
              <a
                class="nav-link"
                :class="$route.name == 'Dashboard' ? 'nav-link-active' : ''"
                href="/"
              >
                <vsx-icon
                  iconName="Category"
                  :size="24"
                  :type="$route.name == 'Dashboard' ? 'bold' : 'linear'"
                />
                <span
                  :class="$route.name == 'Dashboard' ? 'text-neutral500' : ''"
                  class="ml-2"
                  >Dashboard
                </span>
              </a>
            </li>

            <li
              :class="
                $route.name == 'Explore' || $route.name == 'Explore Map'
                  ? 'active'
                  : ''
              "
              class="my-2 my-lg-0 nav-item"
            >
              <a
                class="nav-link"
                :class="
                  $route.name == 'Explore' || $route.name == 'Explore Map'
                    ? 'nav-link-active'
                    : ''
                "
                href="/explore"
              >
                <vsx-icon
                  iconName="Discover"
                  :size="24"
                  :type="
                    $route.name == 'Explore' || $route.name == 'Explore Map'
                      ? 'bold'
                      : 'linear'
                  "
                />
                <span
                  :class="
                    $route.name == 'Explore' || $route.name == 'Explore Map'
                      ? 'text-neutral500'
                      : ''
                  "
                  class="ml-2"
                  >Explore</span
                >
              </a>
            </li>
            <li
              :class="$route.name == 'Membership' ? 'active' : ''"
              class="my-2 my-lg-0 nav-item"
            >
              <a
                class="nav-link"
                :class="$route.name == 'Membership' ? 'nav-link-active' : ''"
                href="/membership"
              >
                <vsx-icon
                  :iconName="
                    $route.name == 'Membership' ? 'Category' : 'Category'
                  "
                  :size="24"
                  :type="$route.name == 'Membership' ? 'bold' : 'linear'"
                />
                <span
                  :class="$route.name == 'Membership' ? 'text-neutral500' : ''"
                  class="ml-2"
                  >Membership
                </span>
              </a>
            </li>
            <!-- profilmenu -->

            <div class="d-block d-lg-none h-100">
              <li
                :class="$route.name == 'Following' ? 'active' : ''"
                class="my-2 my-lg-0 nav-item"
              >
                <a
                  class="nav-link"
                  :class="$route.name == 'Following' ? 'nav-link-active' : ''"
                  href="/following"
                >
                  <vsx-icon
                    :iconName="
                      $route.name == 'Following' ? 'UserTag' : 'UserTag'
                    "
                    :size="24"
                    :type="$route.name == 'Following' ? 'bold' : 'linear'"
                  />
                  <span
                    :class="$route.name == 'Following' ? 'text-neutral500' : ''"
                    class="ml-2"
                    >Following
                  </span>
                </a>
              </li>
              <li
                :class="$route.name == 'Profil' ? 'active' : ''"
                class="my-2 my-lg-0 nav-item"
              >
                <a
                  class="nav-link"
                  :class="$route.name == 'Profil' ? 'nav-link-active' : ''"
                  href="/profil"
                >
                  <vsx-icon
                    :iconName="
                      $route.name == 'Profil'
                        ? 'ProfileCircle'
                        : 'ProfileCircle'
                    "
                    :size="24"
                    :type="$route.name == 'Profil' ? 'bold' : 'linear'"
                  />
                  <span
                    :class="$route.name == 'Profil' ? 'text-neutral500' : ''"
                    class="ml-2"
                    >Profile Setting
                  </span>
                </a>
              </li>
              <a
                class="btn btn-block btn-danger p-3 font-size-14 mt-5"
                href="/signin"
              >
                <span class="mr-2">
                  <vsx-icon
                    iconName="Logout"
                    :size="20"
                    type="linear"
                    class=""
                  />
                </span>
                Log Out
              </a>
            </div>
          </ul>
          <div
            class="d-none d-lg-block ml-auto"
            v-if="$route.meta.logged === true"
          >
            <Notif />
          </div>
          <a
            href="/merchant"
            class="btn btn-outline-neutral500 px-4 py-2 font-weight-bold font-size-14 my-3 d-none d-lg-inline-block my-lg-0"
            :class="$route.meta.logged === true ? '' : 'ml-auto'"
            type="submit"
          >
            Switch to Merchant
          </a>
          <ul
            class="navbar-nav d-none d-lg-block"
            v-if="$route.meta.logged === true"
          >
            <li class="nav-item dropdown">
              <a
                class="nav-link py-0"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="/assets/img/user.png"
                  width="40"
                  height="40"
                  class="rounded-circle mr-1"
                />
                <span>
                  <vsx-icon
                    iconName="ArrowDown2"
                    :size="16"
                    :type="isActive ? 'bold' : 'linear'"
                    class="ml-1"
                  />
                </span>
              </a>

              <div
                class="dropdown-menu dropdown-menu-right px-4 py-2 mt-2"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a class="dropdown-item p-0 my-3 mr-3" href="/following">
                  <span class="mr-2">
                    <vsx-icon
                      iconName="UserTag"
                      :size="20"
                      type="linear"
                      class="text-neutral500"
                    />
                  </span>
                  Following
                </a>
                <a class="dropdown-item p-0 my-3 mr-3" href="/profil">
                  <span class="mr-2">
                    <vsx-icon
                      iconName="ProfileCircle"
                      :size="20"
                      type="linear"
                      class="text-neutral500"
                    />
                  </span>
                  Profile Setting
                </a>
                <div class="line bg-neutral100"></div>
                <a
                  class="dropdown-item text-danger p-0 my-3 mr-3"
                  href="/signin"
                >
                  <span class="mr-2">
                    <vsx-icon
                      iconName="Logout"
                      :size="20"
                      type="linear"
                      class="text-danger"
                    />
                  </span>
                  Log Out
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
  import { vsxIcon } from "vue-iconsax";
  import Notif from "./Notification.vue";
  // import NavbarCollapse from "./NavbarCollapse.vue";

  export default {
    components: {
      vsxIcon,
      Notif,
      // NavbarCollapse,
    },
    props: ["iconName"],
  };
</script>
