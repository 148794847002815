<template>
  <div class="bg-white">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light bg-light py-3">
        <a class="navbar-brand mr-4_5" href="/merchant/dashboard">
          <img
            src="/assets/img/logo.svg"
            class="img-fluid"
            width="73"
            height="32"
            alt=""
          />
        </a>
        <div
          v-if="$route.meta.loggedMerchant === true"
          class="d-lg-none d-block ml-auto"
        >
          <Notif />
        </div>
        <div
          v-if="$route.meta.loggedMerchant === false"
          class="d-lg-none d-block ml-auto"
        >
          <a
            href="/"
            class="btn btn-outline-neutral500 px-4 py-2 font-weight-bold font-size-14"
            :class="$route.meta.loggedMerchant === true ? '' : 'ml-auto'"
            type="submit"
          >
            Switch to Member
          </a>
        </div>
        <button
          v-if="$route.meta.loggedMerchant === true"
          class="navbar-toggler border-0"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse ml-lg-2"
          id="navbarSupportedContent"
        >
          <div class="d-flex justify-content-between d-lg-none d-block">
            <div class="align-self-center">
              <a
                href="/"
                class="btn btn-outline-neutral500 px-4 py-2 font-weight-bold font-size-14 my-3 d-block d-lg-inline-block my-lg-0"
                :class="$route.meta.loggedMerchant === true ? '' : 'ml-auto'"
                type="submit"
              >
                Switch to Member
              </a>
            </div>
            <div class="align-self-center">
              <button
                v-if="$route.meta.loggedMerchant === true"
                class="navbar-toggler border-0"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="#2c3131"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.16748 4.1665L15.8334 15.8324"
                      stroke="#2c3131"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.16664 15.8324L15.8325 4.1665"
                      stroke="#2c3131"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
          <ul
            v-if="$route.meta.loggedMerchant === true"
            class="navbar-nav my-2 my-lg-0"
          >
            <li class="nav-item dropdown">
              <a
                class="nav-link py-0"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <div class="align-items-center d-flex">
                    <img
                      src="/assets/img/store1.png"
                      class="rounded mr-2_4"
                      width="48"
                      height="48"
                      alt=""
                    />
                    <p class="font-size-14 font-weight-bold">Brodo Semarang</p>
                  </div>

                  <div class="align-self-center">
                    <span>
                      <vsx-icon
                        iconName="ArrowDown2"
                        :size="16"
                        :type="isActive ? 'bold' : 'linear'"
                        class="ml-1"
                      />
                    </span>
                  </div>
                </div>
              </a>

              <div
                class="dropdown-menu dropdown-menu-right px-4 py-2 mt-2"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a class="dropdown-item p-0 my-3 mr-3" href="#">
                  <div class="d-flex justify-content-between mb-4">
                    <div class="d-flex align-items-center">
                      <img
                        src="/assets/img/store1.png"
                        class="rounded mr-2_4"
                        width="48"
                        height="48"
                        alt=""
                      />
                      <p class="font-size-14 font-weight-bold">
                        Brodo Semarang
                      </p>
                    </div>

                    <div class="radio-container align-self-center">
                      <input
                        type="radio"
                        id="merchant-2"
                        name="radio-merchant"
                        checked
                      />
                      <label for="merchant-2"></label>
                    </div>
                  </div>
                </a>
                <a
                  class="dropdown-item p-0 my-3 mr-3 text-neutral500 font-weight-bold"
                  href="/merchant/create/info"
                >
                  <div class="d-flex align-items-center">
                    <div
                      class="rounded mr-2_4 border border-neutral500 border-dashed p-2_5"
                    >
                      <vsx-icon iconName="Add" :size="19" :type="linear" />
                    </div>
                    <p class="font-size-14">Add Merchant</p>
                  </div>
                </a>
              </div>
            </li>
          </ul>
          <ul
            class="navbar-nav mr-auto"
            v-if="$route.meta.loggedMerchant === true"
          >
            <li
              :class="$route.name == 'Merchant Dashboard' ? 'active' : ''"
              class="my-2 my-lg-0 nav-item"
            >
              <a
                class="nav-link"
                :class="
                  $route.name == 'Merchant Dashboard' ? 'nav-link-active' : ''
                "
                href="/merchant/dashboard"
              >
                <vsx-icon
                  :iconName="
                    $route.name == 'Merchant Dashboard'
                      ? 'Category'
                      : 'Category'
                  "
                  :size="24"
                  :type="
                    $route.name == 'Merchant Dashboard' ? 'bold' : 'linear'
                  "
                />
                <span
                  :class="
                    $route.name == 'Merchant Dashboard' ? 'text-neutral500' : ''
                  "
                  class="ml-2"
                  >Merchant Dashboard
                </span>
              </a>
            </li>
            <li
              :class="$route.name == 'Merchant Product' ? 'active' : ''"
              class="my-2 my-lg-0 nav-item"
            >
              <a
                class="nav-link"
                :class="
                  $route.name == 'Merchant Product' ? 'nav-link-active' : ''
                "
                href="/merchant/product"
              >
                <vsx-icon
                  :iconName="
                    $route.name == 'Merchant Product' ? 'Box1' : 'Box1'
                  "
                  :size="24"
                  :type="$route.name == 'Merchant Product' ? 'bold' : 'linear'"
                />
                <span
                  :class="
                    $route.name == 'Merchant Product' ? 'text-neutral500' : ''
                  "
                  class="ml-2"
                  >Product
                </span>
              </a>
            </li>
            <li
              :class="$route.name == 'Merchant Reviews' ? 'active' : ''"
              class="my-2 my-lg-0 nav-item"
            >
              <a
                class="nav-link"
                :class="
                  $route.name == 'Merchant Reviews' ? 'nav-link-active' : ''
                "
                href="/merchant/reviews"
              >
                <vsx-icon
                  :iconName="
                    $route.name == 'Merchant Reviews' ? 'message' : 'message'
                  "
                  :size="24"
                  :type="$route.name == 'Merchant Reviews' ? 'bold' : 'linear'"
                />
                <span
                  :class="
                    $route.name == 'Merchant Reviews' ? 'text-neutral500' : ''
                  "
                  class="ml-2"
                  >Reviews
                </span>
              </a>
            </li>

            <!-- profilmenu -->
            <div class="d-block d-lg-none h-100">
              <li
                :class="$route.name == 'Merchant Follower' ? 'active' : ''"
                class="my-2 my-lg-0 nav-item"
              >
                <a
                  class="nav-link"
                  :class="
                    $route.name == 'Merchant Follower' ? 'nav-link-active' : ''
                  "
                  href="/merchant/follower"
                >
                  <vsx-icon
                    :iconName="
                      $route.name == 'Merchant Follower' ? 'UserTag' : 'UserTag'
                    "
                    :size="24"
                    :type="
                      $route.name == 'Merchant Follower' ? 'bold' : 'linear'
                    "
                  />
                  <span
                    :class="
                      $route.name == 'Merchant Follower'
                        ? 'text-neutral500'
                        : ''
                    "
                    class="ml-2"
                    >Followers
                  </span>
                </a>
              </li>
              <li
                :class="$route.name == 'Merchant All Promo' ? 'active' : ''"
                class="my-2 my-lg-0 nav-item"
              >
                <a
                  class="nav-link"
                  :class="
                    $route.name == 'Merchant All Promo' ? 'nav-link-active' : ''
                  "
                  href="/merchant/all-promo"
                >
                  <vsx-icon
                    :iconName="
                      $route.name == 'Merchant All Promo'
                        ? 'TicketDiscount'
                        : 'TicketDiscount'
                    "
                    :size="24"
                    :type="
                      $route.name == 'Merchant All Promo' ? 'bold' : 'linear'
                    "
                  />
                  <span
                    :class="
                      $route.name == 'Merchant All Promo'
                        ? 'text-neutral500'
                        : ''
                    "
                    class="ml-2"
                    >My Promo
                  </span>
                </a>
              </li>
              <li
                :class="
                  $route.name == 'Merchant Membership Setting' ? 'active' : ''
                "
                class="my-2 my-lg-0 nav-item"
              >
                <a
                  class="nav-link"
                  :class="
                    $route.name == 'Merchant Membership Setting'
                      ? 'nav-link-active'
                      : ''
                  "
                  href="/merchant/membership-setting"
                >
                  <vsx-icon
                    :iconName="
                      $route.name == 'Merchant Membership Setting'
                        ? 'Crown1'
                        : 'Crown'
                    "
                    :size="24"
                    :type="
                      $route.name == 'Merchant Membership Setting'
                        ? 'bold'
                        : 'linear'
                    "
                  />
                  <span
                    :class="
                      $route.name == 'Merchant Membership Setting'
                        ? 'text-neutral500'
                        : ''
                    "
                    class="ml-2"
                    >Membership Setting
                  </span>
                </a>
              </li>
              <li
                :class="$route.name == 'Merchant Setting' ? 'active' : ''"
                class="my-2 my-lg-0 nav-item"
              >
                <a
                  class="nav-link"
                  :class="
                    $route.name == 'Merchant Setting' ? 'nav-link-active' : ''
                  "
                  href="/merchant/merchant-setting"
                >
                  <vsx-icon
                    :iconName="
                      $route.name == 'Merchant Setting'
                        ? 'Setting2'
                        : 'Setting2'
                    "
                    :size="24"
                    :type="
                      $route.name == 'Merchant Setting' ? 'bold' : 'linear'
                    "
                  />
                  <span
                    :class="
                      $route.name == 'Merchant Setting' ? 'text-neutral500' : ''
                    "
                    class="ml-2"
                    >Merchant Setting
                  </span>
                </a>
              </li>
              <a
                class="btn btn-block btn-danger p-3 font-size-14 mt-5"
                href="/signin"
              >
                <span class="mr-2">
                  <vsx-icon
                    iconName="Logout"
                    :size="20"
                    type="linear"
                    class=""
                  />
                </span>
                Log Out
              </a>
            </div>
          </ul>
          <div
            class="d-none d-lg-block ml-auto"
            v-if="$route.meta.loggedMerchant === true"
          >
            <Notif />
          </div>
          <a
            href="/"
            class="btn btn-outline-neutral500 px-4 py-2 font-weight-bold font-size-14 my-3 d-none d-lg-inline-block my-lg-0"
            :class="$route.meta.loggedMerchant === true ? '' : 'ml-auto'"
            type="submit"
          >
            Switch to Member
          </a>
          <ul
            class="navbar-nav d-none d-lg-block"
            v-if="$route.meta.loggedMerchant === true"
          >
            <li class="nav-item dropdown">
              <a
                class="nav-link py-0"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="/assets/img/user.png"
                  width="40"
                  height="40"
                  class="rounded-circle mr-1"
                />
                <span>
                  <vsx-icon
                    iconName="ArrowDown2"
                    :size="16"
                    :type="isActive ? 'bold' : 'linear'"
                    class="ml-1"
                  />
                </span>
              </a>

              <div
                class="dropdown-menu dropdown-menu-right px-4 py-2 mt-2"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a
                  class="dropdown-item p-0 my-3 mr-3"
                  href="/merchant/follower"
                >
                  <span class="mr-2">
                    <vsx-icon
                      iconName="UserTag"
                      :size="20"
                      type="linear"
                      class="text-neutral500"
                    />
                  </span>
                  Followers
                </a>
                <a
                  class="dropdown-item p-0 my-3 mr-3"
                  href="/merchant/all-promo"
                >
                  <span class="mr-2">
                    <vsx-icon
                      iconName="TicketDiscount"
                      :size="20"
                      type="linear"
                      class="text-neutral500"
                    />
                  </span>
                  My Promo
                </a>

                <a
                  class="dropdown-item p-0 my-3 mr-3"
                  href="/merchant/membership-setting"
                >
                  <span class="mr-2">
                    <vsx-icon
                      iconName="Crown"
                      :size="20"
                      type="linear"
                      class="text-neutral500"
                    />
                  </span>
                  Membership Setting
                </a>
                <a
                  class="dropdown-item p-0 my-3 mr-3"
                  href="/merchant/merchant-setting"
                >
                  <span class="mr-2">
                    <vsx-icon
                      iconName="Setting2"
                      :size="20"
                      type="linear"
                      class="text-neutral500"
                    />
                  </span>
                  Merchant Setting
                </a>
                <div class="line bg-neutral100"></div>
                <a
                  class="dropdown-item text-danger p-0 my-3 mr-3"
                  href="/signin"
                >
                  <span class="mr-2">
                    <vsx-icon
                      iconName="Logout"
                      :size="20"
                      type="linear"
                      class="text-danger"
                    />
                  </span>
                  Log Out
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
  import { vsxIcon } from "vue-iconsax";
  import Notif from "./Notification.vue";
  // import NavbarCollapse from "./NavbarCollapse.vue";

  export default {
    components: {
      vsxIcon,
      Notif,
      // NavbarCollapse,
    },
    props: ["iconName"],
  };
</script>
