<template>
  <div class="row">
    <div class="col-lg-8 order-last order-md-first mt-4 m-md-0">
      <div class="row">
        <div class="col-md-12">
          <div class="bg-gradient-hero p-4 rounded-xl">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100 justify-content-between">
                  <div class="">
                    <h3 class="text-white font-weight-bold font-size-24 mb-3">
                      Join VIP Member now!
                      <span>
                        <vsx-icon
                          iconName="Crown1"
                          :size="32"
                          type="bold"
                          class="text-primary500 ml-3"
                        />
                      </span>
                    </h3>
                    <p class="text-neutral100 font-size-14 mb-3 mb-lg-0">
                      Get the latest updates and exclusive deals with our
                      Merchant by joining VIP membership! There's 3 VIP member
                      you can choose from with each exclusive content to be
                      enjoyed!
                    </p>
                  </div>

                  <a
                    type="button"
                    href="/membership"
                    class="btn btn-white font-size-14 p-2_5 w-50 font-weight-bold"
                  >
                    Join Member Now
                  </a>
                </div>
              </div>
              <div class="col-lg-6 d-none d-lg-block">
                <img
                  src="/assets/img/g10.png"
                  class="img-fluid mt-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-5 order-last order-md-first mt-4 m-md-0">
          <a href="/all-promo" class="text-neutral500">
            <div class="bg-white p-4 rounded-xl h-100">
              <p class="font-size-18 font-weight-bold mb-3">Deals for you!</p>
              <div class="position-relative w-100 mb-3">
                <img
                  src="/assets/img/merchant.png"
                  class="img-fluid w-100"
                  alt=""
                />
                <div
                  class="d-inline-flex align-items-center bg-white rounded-sm p-2 position-absolute name-merchant"
                >
                  <img
                    src="/assets/img/merchant-profil.png"
                    class="img-fluid rounded mr-2"
                    height="16"
                    width="16"
                    alt=""
                  />
                  <p class="font-size-14">Name Merchant</p>
                </div>
              </div>
              <div class="pt-3">
                <p>Get 50% Off!</p>
                <p class="text-neutral300 font-size-14">
                  Get promo up to 50% by purchasing 2 items!
                </p>
              </div>
              <div class="bg-neutral100 w-100 line my-3"></div>
              <div class="d-flex justify-content-between font-size-14">
                <div class="">
                  <p class="font-weight-medium">
                    <span class="text-neutral300 font-weight-normal"
                      >Valid until</span
                    >
                    31 April 2023
                  </p>
                </div>
                <div class="">
                  <vsx-icon
                    iconName="ArrowRight2"
                    :size="16"
                    type="linear"
                    class="text-neutral500"
                  />
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-md-7 order-first order-md-last">
          <div class="bg-white rounded-xl px-4 pt-4 pb-1 h-100">
            <div class="d-flex flex-coloumn justify-content-between">
              <p class="text-neutral500 font-size-18 font-weight-bold">
                Visit history
              </p>
              <a
                class="text-primary500 font-size-14 align-self-center"
                href="/visit-history"
                >See All</a
              >
            </div>
            <div class="mt-3">
              <div class="d-flex flex-coloumn w-100 mb-3_5">
                <div class="mr-3">
                  <img
                    src="/assets/img/merchant-profil.png"
                    class="rounded-l img-fliud"
                    alt=""
                    height="40"
                    width="40"
                  />
                </div>
                <div class="w-100">
                  <div class="mb-0_5">
                    <p class="text-neutral500 font-size-14 font-weight-medium">
                      Name Merchant
                    </p>
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                    <p class="text-neutral300 font-size-14">
                      “Buy 1 get 1” voucher used
                    </p>
                    <p class="text-neutral300 font-size-12">4 Jan 2023</p>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-coloumn w-100 mb-3_5">
                <div class="mr-3">
                  <img
                    src="/assets/img/merchant-profil.png"
                    class="rounded-l img-fliud"
                    alt=""
                    height="40"
                    width="40"
                  />
                </div>
                <div class="w-100">
                  <div class="mb-0_5">
                    <p class="text-neutral500 font-size-14 font-weight-medium">
                      Name Merchant
                    </p>
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                    <p class="text-neutral300 font-size-14">
                      “Buy 1 get 1” voucher used
                    </p>
                    <p class="text-neutral300 font-size-12">4 Jan 2023</p>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-coloumn w-100 mb-3_5">
                <div class="mr-3">
                  <img
                    src="/assets/img/merchant-profil.png"
                    class="rounded-l img-fliud"
                    alt=""
                    height="40"
                    width="40"
                  />
                </div>
                <div class="w-100">
                  <div class="mb-0_5">
                    <p class="text-neutral500 font-size-14 font-weight-medium">
                      Name Merchant
                    </p>
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                    <p class="text-neutral300 font-size-14">
                      “Buy 1 get 1” voucher used
                    </p>
                    <p class="text-neutral300 font-size-12">4 Jan 2023</p>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-coloumn w-100 mb-3_5">
                <div class="mr-3">
                  <img
                    src="/assets/img/merchant-profil.png"
                    class="rounded-l img-fliud"
                    alt=""
                    height="40"
                    width="40"
                  />
                </div>
                <div class="w-100">
                  <div class="mb-0_5">
                    <p class="text-neutral500 font-size-14 font-weight-medium">
                      Name Merchant
                    </p>
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                    <p class="text-neutral300 font-size-14">
                      “Buy 1 get 1” voucher used
                    </p>
                    <p class="text-neutral300 font-size-12">4 Jan 2023</p>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-coloumn w-100 mb-3_5">
                <div class="mr-3">
                  <img
                    src="/assets/img/merchant-profil.png"
                    class="rounded-l img-fliud"
                    alt=""
                    height="40"
                    width="40"
                  />
                </div>
                <div class="w-100">
                  <div class="mb-0_5">
                    <p class="text-neutral500 font-size-14 font-weight-medium">
                      Name Merchant
                    </p>
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                    <p class="text-neutral300 font-size-14">
                      “Buy 1 get 1” voucher used
                    </p>
                    <p class="text-neutral300 font-size-12">4 Jan 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 order-first order-md-last">
      <div class="bg-white rounded-xl p-4 h-100">
        <div class="text-center mb-4">
          <img src="/assets/img/user.png" class="mb-3 rounded-circle" alt="" />
          <h6 class="font-size-24 mb-0">John Doe</h6>
          <a href="" class="text-neutral300">Johndoe.gans@gmail.com</a>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 mb-2 mb-sm-0 pr-sm-2">
            <div
              class="font-size-14 d-flex align-items-center font-weight-semi-bold justify-content-center p-2_5 rounded-lg bg-secondary100 border border-secondary500"
            >
              <vsx-icon
                iconName="Crown1"
                :size="20"
                type="bold"
                class="text-secondary500 mr-1"
              />
              Gold Member
            </div>
          </div>
          <div class="col-12 col-sm-6 pl-sm-2">
            <div
              type="button"
              data-toggle="modal"
              data-target="#exampleModalqr"
              class="font-size-14 d-flex align-items-center font-weight-bold justify-content-center p-2_5 rounded-lg bg-white border border-neutral500"
            >
              <vsx-icon
                iconName="ScanBarcode"
                :size="20"
                type="bold"
                class="text-neutral500 mr-2"
              />
              Show QR
            </div>
          </div>
        </div>
        <div class="bg-neutral100 w-100 line my-3"></div>
        <h6 class="text-neutral500 mb-2_4">Statistic</h6>
        <div class="row mb-3">
          <div class="col-6 pr-2">
            <div class="border rounded-l p-3 border-neutral100">
              <div class="d-inline-block rounded-l bg-success p-1 mb-4">
                <vsx-icon
                  iconName="Chart21"
                  :size="24"
                  type="bold"
                  class="text-white"
                />
              </div>
              <h4 class="mb-1">512</h4>
              <p class="text-neutral300 font-size-14">Merchant Visit</p>
            </div>
          </div>
          <div class="col-6 pl-2">
            <div class="border rounded-l p-3 border-neutral100">
              <div class="d-inline-block rounded-l bg-warning p-1 mb-4">
                <vsx-icon
                  iconName="UserTag"
                  :size="24"
                  type="bold"
                  class="text-white"
                />
              </div>
              <h4 class="mb-1">128</h4>
              <p class="text-neutral300 font-size-14">Following</p>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6 pr-2">
            <div class="border rounded-l p-3 border-neutral100">
              <div class="d-inline-block rounded-l bg-information p-1 mb-4">
                <vsx-icon
                  iconName="TicketDiscount"
                  :size="24"
                  type="bold"
                  class="text-white"
                />
              </div>
              <h4 class="mb-1">24</h4>
              <p class="text-neutral300 font-size-14">Promo Used</p>
            </div>
          </div>
          <div class="col-6 pl-2">
            <div class="border rounded-l p-3 border-neutral100">
              <div class="d-inline-block rounded-l bg-danger p-1 mb-4">
                <vsx-icon
                  iconName="Message"
                  :size="24"
                  type="bold"
                  class="text-white"
                />
              </div>
              <h4 class="mb-1">82</h4>
              <p class="text-neutral300 font-size-14">Testimony Added</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal QR-->
  <div
    class="modal fade"
    id="exampleModalqr"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content rounded-llg border-0">
        <div
          class="modal-header position-relative border-bottom border-neutral200 px-4"
        >
          <button
            type="button"
            class="close position-absolute px-0"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="#2c3131"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16748 4.1665L15.8334 15.8324"
                  stroke="#2c3131"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.16664 15.8324L15.8325 4.1665"
                  stroke="#2c3131"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>

          <h5
            class="modal-title font-size-14 font-weight-bold text-center w-100"
            id="exampleModalLabel"
          >
            Show QR
          </h5>
        </div>
        <div class="modal-body p-4">
          <div class="text-center p-4 bg-fafafa mb-4">
            <img
              src="/assets/img/user.png"
              class="mb-3 rounded-circle"
              alt=""
            />
            <h6 class="font-size-24 mb-0">John Doe</h6>
            <a href="" class="text-neutral300">Johndoe.gans@gmail.com</a>
          </div>
          <div class="text-center">
            <img src="/assets/img/QR.svg" alt="" class="img-fluid" />
          </div>
        </div>
        <div
          class="modal-footer justify-content-start border-top border-neutral200 px-4"
        >
          <button
            type="button"
            class="btn btn-block btn-primary500 text-white p-2_5"
            data-dismiss="modal"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { vsxIcon } from "vue-iconsax";
  export default {
    components: {
      vsxIcon,
    },
    props: ["iconName"],
    data() {
      return {
        showPassword: false,
        password: null,
      };
    },
    // computed: {
    //   buttonLabel() {
    //     return this.showPassword ? "Hide" : "Show";
    //   },
    // },
    methods: {
      showpass() {
        this.showPassword = !this.showPassword;
      },
    },
  };
</script>
