import Vue, { createApp } from "@vue/compat";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import { BootstrapVue } from "bootstrap-vue";
import L from "leaflet";
import { BToast } from "bootstrap-vue";
Vue.component("b-toast", BToast);
import CKEditor from "@ckeditor/ckeditor5-vue";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
createApp(App).use(router).use(CKEditor).mount("#app");
