import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    meta: { landing: false, merchant: false, logged: true },
    component: Dashboard,
  },
  {
    path: "/visit-history",
    name: "Visit History",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "membership" */ "../views/VisitHistory.vue"),
  },
  {
    path: "/following",
    name: "Following",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "membership" */ "../views/Following.vue"),
  },
  {
    path: "/detail-store",
    name: "Detail Store",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "membership" */ "../views/DetailStore.vue"),
  },
  {
    path: "/all-product",
    name: "All Product",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "membership" */ "../views/AllProduct.vue"),
  },
  {
    path: "/all-promo",
    name: "All Promo",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "membership" */ "../views/AllPromo.vue"),
  },
  {
    path: "/all-review",
    name: "All Review",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "membership" */ "../views/AllReview.vue"),
  },
  {
    path: "/membership",
    name: "Membership",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "membership" */ "../views/Membership.vue"),
  },
  {
    path: "/membership/checkout",
    name: "Membership Checkout",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(
        /* webpackChunkName: "membership" */ "../views/MembershipCheckout.vue"
      ),
  },
  {
    path: "/membership/checkoutstep2",
    name: "Membership Checkout Step 2",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(
        /* webpackChunkName: "membership" */ "../views/MembershipCheckout2.vue"
      ),
  },
  {
    path: "/membership/checkoutstep3",
    name: "Membership Checkout Step 3",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(
        /* webpackChunkName: "membership" */ "../views/MembershipCheckout3.vue"
      ),
  },
  {
    path: "/profil",
    name: "Profil",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "Profil" */ "../views/profil/Profil.vue"),
  },
  {
    path: "/profil/information",
    name: "Information",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(
        /* webpackChunkName: "Profil" */ "../views/profil/Information.vue"
      ),
  },
  {
    path: "/profil/login-details",
    name: "Login Details",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(
        /* webpackChunkName: "Profil" */ "../views/profil/LoginDetails.vue"
      ),
  },
  {
    path: "/profil/notification",
    name: "Notification",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(
        /* webpackChunkName: "Profil" */ "../views/profil/Notification.vue"
      ),
  },
  {
    path: "/profil/preference",
    name: "Preference",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "Profil" */ "../views/profil/Preference.vue"),
  },
  {
    path: "/profil/membership",
    name: "MembershipBill",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "Profil" */ "../views/profil/Membership.vue"),
  },
  {
    path: "/profil/membership/details",
    name: "Membership Details",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(
        /* webpackChunkName: "Profil" */ "../views/profil/MembershipDetails.vue"
      ),
  },
  {
    path: "/profil/scurity",
    name: "Security",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "Profil" */ "../views/profil/Security.vue"),
  },
  {
    path: "/profil/payment",
    name: "Payment",
    meta: { landing: false, merchant: false, logged: true },

    component: () =>
      import(/* webpackChunkName: "Profil" */ "../views/profil/Payment.vue"),
  },
  {
    path: "/explore",
    name: "Explore",
    meta: { landing: false, merchant: false, logged: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Explore.vue"),
  },
  {
    path: "/explore-map",
    name: "Explore Map",
    meta: { landing: false, merchant: false, logged: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ExploreMap.vue"),
  },
  {
    path: "/map",
    name: "Map",
    meta: { landing: false, merchant: false, logged: true },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Maps.vue"),
  },
  {
    path: "/signin",
    name: "Sign In",
    logged: false,
    meta: { landing: false, merchant: false, logged: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/SignIn.vue"),
  },
  {
    path: "/register",
    name: "Sign Up",
    logged: false,
    meta: { landing: false, merchant: false, logged: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/forgot",
    name: "Forgot Password",
    meta: { landing: false, merchant: false, logged: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ForgotPassword.vue"),
  },
  {
    path: "/forgototp",
    name: "Forgot OTP",
    meta: { landing: false, merchant: false, logged: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/ForgotOTP.vue"),
  },
  {
    path: "/newpass",
    name: "New Password",
    meta: { landing: false, merchant: false, logged: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/NewPassword.vue"),
  },
  {
    path: "/merchant",
    name: "Merchant",
    meta: { landing: false, merchant: true, loggedMerchant: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/merchant/Merchant.vue"),
  },
  {
    path: "/merchant/create/info",
    name: "Merchant Create",
    meta: { landing: false, merchant: true, loggedMerchant: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/merchant/create/Info.vue"),
  },
  {
    path: "/merchant/create/point",
    name: "Merchant Create Point",
    meta: { landing: false, merchant: true, loggedMerchant: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/merchant/create/Point.vue"),
  },
  {
    path: "/merchant/create/photos",
    name: "Merchant Create Photos",
    meta: { landing: false, merchant: true, loggedMerchant: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/merchant/create/Photos.vue"),
  },
  {
    path: "/merchant/create/product",
    name: "Merchant Create Product",
    meta: { landing: false, merchant: true, loggedMerchant: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/merchant/create/Product.vue"),
  },
  {
    path: "/merchant/create/success",
    name: "Merchant Create Success",
    meta: { landing: false, merchant: true, loggedMerchant: false },

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/merchant/create/Success.vue"),
  },
  {
    path: "/merchant/dashboard",
    name: "Merchant Dashboard",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/Dashboard.vue"),
  },
  {
    path: "/merchant/product",
    name: "Merchant Product",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/Product.vue"),
  },
  {
    path: "/merchant/reviews",
    name: "Merchant Reviews",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/Reviews.vue"),
  },
  {
    path: "/merchant/membership-setting",
    name: "Merchant Membership Setting",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/MembershipSetting.vue"),
  },
  {
    path: "/merchant/membership-setting/edit",
    name: "Merchant Edit Membership Setting",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/MembershipSettingEdit.vue"),
  },
  {
    path: "/merchant/merchant-setting",
    name: "Merchant Setting",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/MerchantSetting.vue"),
  },
  {
    path: "/merchant/merchant-setting/notification",
    name: "Merchant Setting Notification",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () =>
      import("../views/merchant/merchantsetting/Notification.vue"),
  },
  {
    path: "/merchant/merchant-setting/information",
    name: "Merchant Setting Information",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () =>
      import("../views/merchant/merchantsetting/Information.vue"),
  },
  {
    path: "/merchant/merchant-setting/location",
    name: "Merchant Setting Location",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/merchantsetting/Location.vue"),
  },
  {
    path: "/merchant/merchant-setting/photos",
    name: "Merchant Setting Photos",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/merchantsetting/Photos.vue"),
  },
  {
    path: "/merchant/merchant-setting/preference",
    name: "Merchant Setting Preference",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/merchantsetting/Preference.vue"),
  },
  {
    path: "/merchant/merchant-setting/Security",
    name: "Merchant Setting Security",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/merchantsetting/Security.vue"),
  },
  {
    path: "/merchant/my-promo",
    name: "Merchant MyPromo",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/MyPromo.vue"),
  },
  {
    path: "/merchant/my-promo/edit",
    name: "Merchant MyPromo Edit",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/MyPromoEdit.vue"),
  },
  {
    path: "/merchant/all-promo",
    name: "Merchant All Promo",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/AllPromo.vue"),
  },
  {
    path: "/merchant/follower",
    name: "Merchant Follower",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/Follower.vue"),
  },
  {
    path: "/merchant/product",
    name: "Merchant Product",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/Product.vue"),
  },
  {
    path: "/merchant/product/new",
    name: "Merchant Product New",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/ProductNew.vue"),
  },
  {
    path: "/merchant/product/edit",
    name: "Merchant Product Edit",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/ProductEdit.vue"),
  },
  {
    path: "/merchant/identity-verif",
    name: "Merchant Identity Verif",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/IdentityVerif.vue"),
  },
  {
    path: "/merchant/identity-verif/ktp",
    name: "Merchant Identity Verif Ktp",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/IdentityVerifKtp.vue"),
  },
  {
    path: "/merchant/identity-verif/success",
    name: "Merchant Identity Verif Success",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/IdentityVerifSuccess.vue"),
  },
  {
    path: "/merchant/register-merch",
    name: "Merchant RegisterMerch",
    meta: { landing: false, merchant: true, loggedMerchant: true },
    component: () => import("../views/merchant/RegisterMerch.vue"),
  },

  //landing
  {
    path: "/landing",
    name: "Landing Page",
    meta: {
      landing: true,
      merchant: false,
      loggedMerchant: false,
    },
    component: () => import("../views/landing/Landing1.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;
