<template>
  <nav
    class="navbar navbar-expand-lg navbar-light navbar-landing bg-light py-3"
  >
    <div class="container">
      <a class="navbar-brand mr-lg-5" href="/merchant/dashboard">
        <img
          src="/assets/img/logo.svg"
          class="img-fluid"
          width="73"
          height="32"
          alt=""
        />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse ml-lg-2" id="navbarSupportedContent">
        <div class="d-flex justify-content-between d-lg-none d-block">
          <div class="align-self-center"></div>
          <div class="align-self-center">
            <button
              class="navbar-toggler border-0"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="#2c3131"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.16748 4.1665L15.8334 15.8324"
                    stroke="#2c3131"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.16664 15.8324L15.8325 4.1665"
                    stroke="#2c3131"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <ul class="navbar-nav mr-auto">
          <li class="my-2 my-lg-0 nav-item">
            <a href="#home" class="nav-link nav-link-landing-active"> Home </a>
          </li>
          <li class="my-2 my-lg-0 nav-item">
            <a href="#" class="nav-link"> Benefits </a>
          </li>
          <li class="my-2 my-lg-0 nav-item">
            <a href="#" class="nav-link"> Pricing </a>
          </li>
          <li class="my-2 my-lg-0 nav-item">
            <a href="#" class="nav-link"> FAQ </a>
          </li>
        </ul>
        <a
          href="/signin"
          class="btn text-neutral500 btn-login px-4 py-2 font-weight-bold font-size-14 my-2 my-lg-0 d-block d-lg-inline-block ml-auto border-0"
          type="submit"
        >
          Login & Register
        </a>

        <a
          href="#"
          class="btn btn-neutral500 px-4 py-2 font-weight-bold font-size-14 my-2 my-lg-0 d-block d-lg-inline-block ml-lg-5 ml-0"
          type="submit"
        >
          Download App
        </a>
      </div>
    </div>
  </nav>
</template>
<script>
  import { vsxIcon } from "vue-iconsax";

  export default {
    components: {
      vsxIcon,
    },
    props: ["iconName"],
  };
</script>
<style scoped>
  .nav-link-landing-active {
    color: var(--neutral500) !important;
  }
</style>
